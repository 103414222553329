/* src/App.css */
.section {
    min-height: 100vh;
    padding: 100px 20px;
  }
  
  #naturopathy {
    background-color: #fff2fa;
  }
  
  #about-us {
    background-color: #faf0e6;
  }
  
  #services {
    background-color: #e1efff;
  }
  
  #benefits {
    background-color: #f5f5f5;
  }
  
  /* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darken background for focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content with Background Image */
.modal-content {
  background: url('https://img.freepik.com/free-vector/copy-space-bokeh-spring-lights-background_52683-55649.jpg') no-repeat center center/cover;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  width: 350px;
  height: 400px;
  position: relative;
  color: white; /* Ensure text is readable */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Modal Heading */
.modal-content h2 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #010101; /* White text for better visibility */
}

/* Blinking Button */
.blink-button {
  background-color: #ff9800; /* Bright color for contrast */
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  animation: blink 1s infinite; /* Blinking effect */
  margin-top: 20px;
}

/* Blinking animation */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

/* Close Button */
.close-button {
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  border: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Adjust paragraph text inside the modal */
.modal-content p {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 16px;
  color: rgb(16, 16, 16); /* Ensure readability on background image */
}

  